/** ****** My Swiss Lunch v0.1 ****** */
/** ****** MSL Juin 2021      ****** */
/** *** About.VUE Page d'informations **** */

/** b-collapse avec le contenu  */



<template>
  <div class="about">
            <div>
              <div class="divider is-centered">
                <h6>My Swiss Lunch - Comment ça marche ?</h6>
            
            </div>
            </div>
    <section>    <div class="container">


    

      <b-collapse
        class="card"
        animation="slide"
        aria-id="contentIdForA11y3"
        :open="false"
      >
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3"
          >
            <p class="card-header-title"> My Swiss Lunch, c'est quoi ?</p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
            </a>
          </div>
        </template>

        <div class="card-content">
          <div class="content">
            My Swiss Lunch est une plateforme gratuite permettant de trouver à proximité de votre position ou d’une
adresse des plats du jour et de les réserver à l’emporter ou sur place à l’heure de votre choix
jusqu’à une semaine à l’avance.<br/>
Cette plateforme vous permet aussi de connaître le prix, le nombre de plats restants, le
nombres de places disponibles, de consulter directement le site du restaurateur et selon les
quantités, de réserver à l’emporter pour le soir aussi.<br/>
En quelque clics vous êtes sûr d’avoir votre plat du jour et sûrement de découvrir de nouvelles
adresses.<br/>
          </div>
        </div>
      </b-collapse>

      <b-collapse
        class="card"
        animation="slide"
        aria-id="contentIdForA11y3"
        :open="false"
      >
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3"
          >
            <p class="card-header-title">Comment s'inscrire ?</p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
            </a>
          </div>
        </template>

        <div class="card-content">
          <div class="content">
            Pour s'inscrire, rien de plus simple  : lorsque le site sera ouvert, vous n'aurez qu'à cliquer sur "S'inscrire" en
            haut de cette fenêtre.<br/> Voici un résumé de la procédure :

            <br />
            1. Entrez votre nom, votre prénom, votre adresse email (pour
            recevoir les confirmations de commande, rien de plus ! ) <br />
            2. Choisissez un mot de passe sécurisé, et entrez le deux fois, pour
            être sûr de ne pas l'oublier ! <br />
            3. Entrez un numéro de téléphone mobile français ou suisse (autres
            pays à venir). Attention, vérifiez le bien, car un sms vous sera
            envoyé pour valider la commande <br />
            4. Validez le "Captcha" pour prouver que vous n'êtes pas un robot !
            <br />
            5. Cliquez sur "Valider" <br />
            6. Entrez le code reçu par sms, et validez. <br />
            7. C'est tout ! On passe à la commande !
          </div>
        </div>
      </b-collapse>

      <b-collapse
        class="card"
        animation="slide"
        aria-id="contentIdForA11y3"
        :open="false"
      >
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3"
          >
            <p class="card-header-title">Comment passer une commande ?</p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
            </a>
          </div>
        </template>

        <div class="card-content">
          <div class="content">
            Une fois inscrit, voici comment passer une commande :

            <br />
            1. Connectez-vous à votre compte avec votre email et votre mot de
            passe. <br />
            2. Faites une recherche, soit autour de votre position (activez la
            géolocalisation sur votre appareil), soit avec une adresse que vous
            choisissez <br />
            3. Sélectionnez la date de retrait (aujourd'hui, ou dans la semaine
            qui vient !) <br />
            4. Faites "Rechercher" et cliquez sur le plat qui vous fait envie !
            <br />
            5. Choisissez l'horaire de retrait, la quantité souhaitée, un
            éventuel commentaire, et validez ! <br />
            6. C'est tout ! Vous pouvez aller retirer votre commande au
            restaurant et vous régaler.
          </div>
        </div>
      </b-collapse>

      <b-collapse
        class="card"
        animation="slide"
        aria-id="contentIdForA11y3"
        :open="false"
      >
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3"
          >
            <p class="card-header-title">
              Qui Sommes Nous ?
            </p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
            </a>
          </div>
        </template>

        <div class="card-content">
          <div class="content">
            L’idée de My Swiss Lunch est née au cours d’un déjeuner entre amis.<br/> <br/>L’un des sujet abordé
était la difficulté de connaître les plats du jour proposé par les restaurants autour de nous.
D’autres points intéressants sont ressortis de cette discussion.<br/>
- La contrariété d’aller dans un restaurant dont le plat du jour nous plaisait et de constater une
fois installé, qu’il n’y en avait plus.<br/>
- Découvrir après des années que le petit restaurant du coin de la rue proposait d’excellents plats
du jour mais que jamais nous n’étions passé devant.<br/>
- Le fait de ne pas pouvoir réserver un plat du jour à emporter en fin de journée afin d’éviter le
gaspillage.<br/><br/>
Une chose en amenant une autre, le concept de My Swiss Lunch était donc né.<br/><br/>
Un site simple permettant de choisir un plat du jour parmi les restaurants à proximité, de le
réserver pour midi et pour le soir, sur place ou à emporter et ce jusqu’à une semaine à l’avance.
<br/><br/>
Après plusieurs mois d’échanges, de discussions et beaucoup de rires, le site a enfin vu le jour.<br/>
Tous les points qui nous tenaient à cœur ont été respectés :<br/>
-Un site simple d’utilisation.<br/>
-Un outil efficace pour les utilisateurs comme pour les restaurateurs.<br/>
-Un service permettant d’éviter le gaspillage.<br/>
-La possibilité de découvrir de nouveaux lieux.<br/><br/><br/>
Bon appétit.<br/>
Votre team My Swiss Lunch
          </div>
        </div>
      </b-collapse>

      <b-collapse
        class="card"
        animation="slide"
        aria-id="contentIdForA11y3"
        :open="false"
      >
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3"
          >
            <p class="card-header-title">Questions / Suggestions</p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
            </a>
          </div>
        </template>

        <div class="card-content">
          <div class="content">
            Vous n’avez pas trouvé de réponse dans nos pages ?<br/>
Vous avez des suggestions à nous transmettre ?<br/>
N’hésitez pas à nous contacter en utilisant la rubrique "Nous Contacter"<br/>
          </div>
        </div>
      </b-collapse></div>
    </section>
  </div>
</template>

<script>
export default {
  name:"about"
}
</script>

<style scoped>
.card-header-title:hover {
  background-color:lightgray;
}
</style>